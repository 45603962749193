<template>
  <div class="app-box">
    <x-scroll-view>
      <x-form-box>
        <x-form-input v-if="xhrData.name" label="姓名" disable v-model="xhrData.name"></x-form-input>
        <x-form-select v-show="xhrData.positonType" label="职位" disable :slots="StaffPositonEnum" :bindValue="xhrData.positonType"></x-form-select>
        <!-- <x-form-input v-if="xhrData.otherPositon" label="其他职位" disable v-model="xhrData.otherPositon"></x-form-input> -->
        <template v-if="customerType==1000">
          <x-form-select v-show="xhrData.majorType" label="专业" disable :slots="StaffProfessionEnum" :bindValue="xhrData.majorType"></x-form-select>
          <!-- <x-form-input v-if="xhrData.otherMajor" label="其他专业" disable v-model="xhrData.otherMajor"></x-form-input> -->
        </template>
        <x-form-input v-if="xhrData.fixedLineTelephone" label="固定电话" disable v-model="xhrData.fixedLineTelephone">
          <a class="phone-icon" :href="'tel:'+xhrData.fixedLineTelephone"></a>
        </x-form-input>
        <x-form-input v-if="xhrData.telephone" label="手机号码" disable v-model="xhrData.telephone">
          <a class="phone-icon" :href="'tel:'+xhrData.telephone"></a>
        </x-form-input>
        <x-form-date v-show="xhrData.birthday" label="生日" disable :defaultDate="xhrData.birthday"></x-form-date>
        <x-form-input v-if="xhrData.hobby" label="爱好" disable v-model="xhrData.hobby"></x-form-input>
      </x-form-box>
      <i v-if="!isLeader" class="edit-icon" @click="editEvent"></i>
    </x-scroll-view>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formDate from "@/components/formControl/formDate";
import scrollView from "@/components/scrollView";
import { getStaff } from "@/api/customerCenter";

export default {
  data () {
    return {
      isLeader: JSON.parse(sessionStorage.getItem("token")).isLeader,
      customerType: this.$route.params.customerType,
      StaffPositonEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      StaffProfessionEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      xhrData: {}
    };
  },
  created () {
    this.setEnum();
  },
  methods: {
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      if (this.customerType == 1000) {
        configJson.dataArray.StaffPositonEnum.map(item => {
          this.StaffPositonEnum[0].values.push({
            code: item.value,
            name: item.desc
          });
        });
      } else {
        Object.keys(configJson.DealerStaffPositonEnum).forEach(key => {
          this.StaffPositonEnum[0].values.push({
            code: key,
            name: configJson.DealerStaffPositonEnum[key]
          });
        });
      }
      Object.keys(configJson.StaffProfessionEnum).forEach(key => {
        this.StaffProfessionEnum[0].values.push({
          code: key,
          name: configJson.StaffProfessionEnum[key]
        });
      });
      this.getData();
    },
    getData () {
      getStaff({
        staffId: this.$route.params.staffId
      }).then(xhr => {
        this.xhrData = xhr.data;
        document.title = "";
        document.title = this.xhrData.name;
      });
    },
    editEvent () {
      this.$router.push({
        path: `/customerCenter/staffInfo/edit/edit/${this.customerType}/${this.xhrData.id}`
      });
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formDate.name]: formDate,
    [scrollView.name]: scrollView
  }
};
</script>

<style scoped>
.phone-icon {
  margin-left: 0.1rem;
  width: 0.23rem;
  height: 0.23rem;
  background: url('~@images/phone_icon.png') no-repeat center/0.18rem;
}
</style>
